import React, { useState, useEffect , useRef } from 'react';
import {Link} from 'react-router-dom';

const Services = (props) => {
    // console.log(props)
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState(data);
    const [ componentMounted, setComponentMounted ] = useState(true)
   
    
    // let componentMounted = true;
    useEffect(() => {
        const getServices = async () => {
            
           
            if (componentMounted) {
                // setData(await response.clone().json());
                setData(props.servicios);
                setFilter(props.servicios);
                
                console.log(filter)

            }
            return () => {
                setComponentMounted(false);
            }
        }
        getServices();
    }, [])



    const filterProduct = (cat) =>{
        const updatedList = data.filter((x)=>x.category === cat);
        setFilter(updatedList)
    }   

    return (
        <div>
            <div className="container my-5 py-5">
                <div className="row">
                    <div className="col-12 mb-5">
                        <h1 className="display-6 fw-bolder text-center">Servicios que ofrecemos</h1>
                        <hr />
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="buttons d-flex justify-content-center mb-5 pb-5">
                        {/* <button className="btn btn-outline-dark me-2" onClick={()=>setFilter(data)}>All</button>
                        <button className="btn btn-outline-dark me-2" onClick={()=>filterProduct("men's clothing")}>Men's Clothing</button>
                        <button className="btn btn-outline-dark me-2" onClick={()=>filterProduct("women's clothing")}>Women's Clothing</button>
                        <button className="btn btn-outline-dark me-2" onClick={()=>filterProduct("jewelery")}>Jewelery</button>
                        <button className="btn btn-outline-dark me-2" onClick={()=>filterProduct("electronics")}>Electronic</button> */}
                        
                        <button className="btn btn-outline-dark me-2" onClick={()=>setFilter(data)}>TODOS</button>
                        <button className="btn btn-outline-dark me-2" onClick={()=>filterProduct("certificaciones")}>Certificaciones</button>
                        <button className="btn btn-outline-dark me-2" onClick={()=>filterProduct("escrituras")}>Escrituras Publicas</button>
                        <button className="btn btn-outline-dark me-2" onClick={()=>filterProduct("vehiculares")}>Transferencias Vehiculares</button>
                        <button className="btn btn-outline-dark me-2" onClick={()=>filterProduct("no contenciosos")}>Procesos no contenciosos</button>
                    </div>

                    {
                        filter.map((service) => {
                            return (
                                <div className="col-md-3 mb-4" key={service.id}>
                                    <div className="card h-75 text-center p-0" >
                                        <img src={service.image} className="card-img-top" alt={service.name} height="250px"/>
                                            <div className="card-body">
                                                {/* <h5 className="card-title mb-0">{service.name.substring(0,12)}</h5> */}
                                                <h5 className="card-title mb-0">{service.name}</h5>
                                                <p className="card-text lead fw-bold"></p>
                                                <Link to={`/services/${service.id}`} className="btn btn-outline-dark mb-2">
                                                    Ver detalles
                                                </Link>
                                            </div>
                                    </div>
                                </div>
                            );
                        })
                    }

                </div>
            </div>
        </div>
    );
}

export default Services;