// import logo from './logo.svg';
// import './App.css';
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar.jsx';
import Home from './components/Home.jsx';
import Services from './components/Services.jsx';
import Footer from './components/Footer/Footer.jsx';


function App() {
  return (
    <>
      <Navbar/>
      <Routes>
        
          <Route index element={<Home />} />
          <Route path="/servicios" element={<Services/>} />
          {/* <Route path="/products/:id" element={<Product />} />
          <Route path="/cart" element={<Cart />} /> */}
        
      </Routes>
      <Footer/>
    </>
  );
}

export default App;
