import React from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

// import protegete from '../assets/imgs/protegete-covid.jpeg';
// import protocolos from '../assets/imgs/protocolos-covid.jpeg';
// import distanciamiento from '../assets/imgs/distanciamiento-covid.jpeg';
import edictoMatrimonial from '../assets/imgs/edicto-matrimonial.jpg';

const ModalAds = ({show,handleClose})=>{
  

  return (
    <>
      <Modal show={show} onHide={handleClose} className="modal-lg">
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
            <div className="row">
                <div className="col-sm-12">
                    <img src={edictoMatrimonial} alt="protegete del covid" width="100%"/>
                </div>
                {/* <div className="col-sm-12">
                    <img src={protocolos} alt="protegete del covid" width="100%"/>
                </div>
                <div className="col-sm-12">
                    <img src={distanciamiento} alt="protegete del covid" width="100%"/>
                </div> */}
            </div>
            
            
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default ModalAds;