
import certificaciones from './assets/servicios/certificaciones.jpg';
import inmueble from './assets/servicios/compra-venta-inmuebles.jpg';
import vehicular from './assets/servicios/transferencias-vehiculares.jpg';
import noContenciosos from './assets/servicios/asuntos-no-contenciosos.jpg';
const servicios = [
  {
    id:1,
    name: 'Legalizacion de firmas',
    category: 'certificaciones',
    species: 'Human',
    gender: 'Male',
    image: certificaciones
  },
  { 
    id:2,
    name: 'Compraventa inmueble',
    category: 'escrituras',
    species: 'Human',
    gender: 'Female',
    image: inmueble
  },
  {
    id:3,
    name: 'Transferencia Vehicular',
    category: 'vehiculares',
    species: 'Human',
    gender: 'Female',
    image: vehicular
  },
  {
    id:4,
    name: 'Asuntos no contenciosos',
    category: 'no contenciosos',
    species: 'Human',
    gender: 'Male',
    image: noContenciosos
  },
  
]

export default servicios;
