import React from 'react';
import Style from './Footer.module.css'

const Footer = () => {
    return (

        <div className=''>
            <div className={`text-center text-lg-start text-white p-3 ${Style.footer}`}>
                <div className="container text-center text-md-start mt-5">
                    <div className="row mt-3">
                        <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mb-4">
                            <h6 className="text-uppercase fw-bold mb-4">
                                <i className="fas fa-gem me-3 text-secondary"></i>Notaria Rodriguez Zea
                            </h6>
                            <p>
                                Rene Rodolfo Rodriguez Zea es notario de la ciudad de Juliaca y junto con su equipo ofrecen un servicio profesional y de calidad.
                            </p>
                            
                        </div>
                        <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                            <h6 className="text-uppercase fw-bold mb-4">
                                SERVICIOS
                            </h6>
                            <p>
                                <a href="#!" className="text-reset">Certificaciones</a>
                            </p>
                            <p>
                                <a href="#!" className="text-reset">Escrituras Publicas</a>
                            </p>
                            <p>
                                <a href="#!" className="text-reset">Transferencias Vehiculares</a>
                            </p>
                            <p>
                                <a href="#!" className="text-reset">Asuntos no Contenciosos</a>
                            </p>
                        </div>
                        <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                            <h6 className="text-uppercase fw-bold mb-4">
                                Otros
                            </h6>
                            <p>
                                <a href="#!" className="text-reset">Servicios</a>
                            </p>
                            <p>
                                <a href="#!" className="text-reset">Contacto</a>
                            </p>
                            <p>
                                <a href="#!" className="text-reset">Nosotros</a>
                            </p>
                            <p>
                                <a href="#!" className="text-reset">Ayuda</a>
                            </p>
                        </div>
                        <div className="col-md- col-lg-3 col-xl-4 mx-auto mb-md-0 mb-4">
                            <h6 className="text-uppercase fw-bold mb-4">ContactO</h6>
                            <p><i className="fa fa-home me-1 text-white"></i> JR. BOLIVAR NRO. 340 CERCADO</p>
                            <p>
                                <i className="fa fa-envelope me-1 text-white"></i>
                                notariarodriguezzea@hotmail.com
                            </p>
                            <p><i className="fa fa-phone me-1 text-white"></i> 051 624635</p>
                            <p><i className="fa fa-calendar me-1 text-white"></i> Lun -Vie de  8:30 a 13:00  y de 15:00 a 18:30<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp; Sab. de 8:30 a 13:00</p>
                        </div>
                    </div>
                    <div className="text-center p-4" >
                        © 2023 Copyright:
                        <a className="text-reset fw-bold" target="_blank" rel="noreferrer"href="https://amau.tech/"> amau.tech</a>
                    </div>

            </div>
        </div>
        </div>

    );
}

export default Footer;