import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import ModalAds from './ModalAds';
import Logo from '../assets/imgs/logo-notaria-rodriguez-zea-juliaca.png';


const Navbar = () => {


    // const state = useSelector((state)=>state.handleCart)

    const [show, setShow] = useState(true);//true abierto por defecto/ false hide
    const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);
    return(
        <div>
            <nav className="navbar navbar-expand-lg bg-light  shadow-sm bg-white">
                <div className="container">
                    <Link className="navbar-brand fw-bold fs-4" to="/">
                        <img src={Logo} className="card-img" alt="Notaria Rodriguez Zea" height="80px"/>
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse d-flex justify-content-end" id="navbarSupportedContent">
                        <ul className="navbar-nav mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link active" aria-current="page" to="/">Inicio</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/nosotros">Nosotros</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/">Servicios</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/contacto">Contacto</Link>
                            </li>
                        </ul>
                    </div>
                    {/* <button className="btn btn-outline-success" type="submit" onClick={handleShow}>ABRIR</button> */}
                </div>
            </nav>
            <ModalAds show={show} handleClose={handleClose}/>
        </div>
    );
}

export default Navbar;