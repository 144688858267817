import React from 'react';
import Banner from '../assets/imgs/banner.jpg';
import {Link} from 'react-router-dom';
import Services from './Services';
import servicios from '../data.js'

const Home = () => {
    return (
        <div className="hero">
            <div className="card text-bg-dark border-0">
                <img src={Banner} className="card-img" alt="backgorund" height="550px"/>
                    <div className="card-img-overlay d-flex flex-column justify-content-center bg-dark opacity-75">
                        <div className="container">

                            <h5 className="card-title display-3 fw-bolder mb-0">SERVICIOS NOTARIALES</h5>
                            <p className="card-text lead fs-2">
                                Revise que servicios ofrecemos
                            </p>
                            <div className="buttons">
                            <Link className="btn btn-outline-success btn-lg btn-block" to="/">
                                <i className="fa fa-sign-in me-1 "></i>VER SERVICIOS
                            </Link>
                            
                        </div>
                        </div>
                        
                    </div>
            </div>
            <Services servicios={servicios}/>
        </div>
    );
}

export default Home;